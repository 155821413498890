.topicDetails {
  font-family: 'Montserrat';

  .header {
    display: flex;
    gap: 20px;
    padding: 20px 0 20px 40px;
    border-radius: 20px;
    background: #56BBC6;

    .categoryImage {
      width: 200px;
      height: 200px;
      background: #FFF;
      align-content: center;
      text-align: center;
      border-radius: 100%;
      box-shadow: -8px 5px 6px 0px rgba(0, 0, 0, 0.2);

      img {
        object-fit: contain;
      }
    }

    .categoryTitle {
      display: flex;
      flex-direction: column;
      margin-top: 25px;

      .clinicalTitle {
        color: #E5FAFF;
        font-size: 21px;
        font-weight: 500;
        margin-left: 8px;
      }

      .title {
        font-size: 45px;
        color: #E5FAFF;
        font-weight: 700;
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    gap: 50px;
    padding: 20px 10px 0 20px;

    &.collapsedContent {
      gap: 0;
    }

    .leftContainer {
      width: 30%;
      transition: width 0.5s ease;

      &.collapsedLeftContainer {
        width: 24px;
      }

      .leftContainerHeader {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .typeAndButton {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;

          span {
            margin: 0;
            font-size: 21px;
            font-weight: 800;
            color: #00c7df;
          }

          .menuIcon {
            cursor: pointer;
          }
        }
      }

      .leftContainerContent {
        margin-top: 20px;

        .topicList {
          display: flex;
          flex-direction: column;
          gap: 5px;
          margin: 10px 0 0 5px;

          span {
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
            padding: 4px 5px;
          }

          .selectedTopic {
            background: #1f7698;
            color: #FFF;
            font-weight: 500;
            padding: 4px 10px;
            border-radius: 50px;
            white-space: 'nowrap';
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    .rightContainer {
      width: calc(70% - 50px);
      transition: width 0.5s ease;

      &.collapsedRightContainer {
        width: 100%;
      }

      .rightContainerHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 80px;

        .topicTitle {
          font-size: 24px;
          color: #00c7df;
          font-weight: 800;
        }

        .caseId {
          font-size: 14px;
          color: #FFF;
          background: #1f7698;
          border-radius: 5px;
          padding: 5px 5px;
        }
      }
    }
  }
}