.sidebar {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  font-family: 'Montserrat';

  .sidebarLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }

  .navbarList {
    .mainUl {
      list-style: none;
      color: #D6EAED;
      padding-left: 10px;
      font-size: 18px;
      margin-top: 20px;

      .mainLi {
        cursor: pointer;
        margin-top: 10px;

        .contentArea {
          display: flex;
          align-items: center;
          min-height: 45px;
          padding-left: 20px;
          border-radius: 20px 0 0 20px;
          gap: 10px;
        }

        .subUl {
          border-left: 1px solid #adc9cc;
          margin: 0 0 0 32px;
          padding: 5px 0 0 0;

          &.open {
            max-height: 1000px;
            transition: max-height 0.5s ease-in;
          }

          .subLi {
            width: 100%;
            color: #FFF;
            opacity: 0.5;
            min-height: 40px;
            font-size: 14px;
            display: inline-flex;
            align-items: center;
            padding: 8px 20px;
            transition: all ease 200ms;

            &:hover {
              opacity: 1;
            }
          }

          .activeSub {
            color: #FFF;
            opacity: 1;
            font-weight: 600;
          }
        }
      }

      .active {
        background: #E5FAFF;
        color: #099EAF;
        transition: 0.8s ease;
      }
    }
  }
}