.calendar {
  margin-top: 10px;
  font-family: 'Montserrat';

  .addSession {
    text-align: end;

    button {
      display: inline-block;
      background: #FF5E5C;
      color: #fff;
      border: none;
      text-align: center;
      border-radius: 20px;
      padding: 5px 30px;
    }
  }

  .fullCalendar {
    margin-top: 25px;
  }
}