.myProgress {
  padding-left: 20px;
  margin-top: 10px;
  font-family: 'Montserrat';

  .myProgressHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headerLeftContainer {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .titleAndType {
        font-size: 18px;
        font-weight: 600;
      }

      .feedbackParagraph {
        font-size: 15px;
        font-weight: 600;
      }
    }

    .headerRightContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      cursor: pointer;

      .categorySelectBox {
        width: 250px;
        height: 35px;
        font-weight: 600;
        padding-left: 5px;
        border-radius: 20px;
        cursor: pointer;
      }

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 40px;
        height: 40px;

        &.activeIcon {
          background-color: #099eaf;
        }
      }
    }
  }
}