.learningHub {
  margin-top: 10px;
  font-family: 'Montserrat';

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 20px;

    h5 {
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    input[type=text] {
      border: none;
      background: #CEEBEE;
      border-radius: 5px;
      padding: 5px 10px;
      width: 350px;
      font-size: 14px;
      font-weight: 600;
      position: relative;

      &:focus-visible {
        outline: none;
      }
    }

    .searchIcon {
      position: absolute;
      right: 50px;
      font-size: 20px;
    }
  }

  .content {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;

    .categoryCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      width: calc(20% - 20px);
      min-height: 280px;
      border-radius: 20px;
      position: relative;
      overflow: hidden;

      &:hover {
        & .categoryImage {
          box-shadow: -5px 5px 6px 0px rgba(0, 0, 0, 0.2);
        }

        & span {
          color: #FFF;
        }

        & .opacityImage {
          opacity: 1;
        }

        & .opacityCard {
          opacity: 1;
        }
      }

      .categoryImage {
        width: 150px;
        height: 150px;
        background: #FFF;
        align-content: center;
        text-align: center;
        border-radius: 100%;
        border: 1px solid #099eaf;
        position: relative;
        z-index: 2;
        transition: all ease 300ms;

        img {
          object-fit: contain;
        }
      }

      span {
        color: #099eaf;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        transition: all ease 100ms;
        text-align: center;
        z-index: 2;
      }

      .opacityCard {
        position: absolute;
        inset: 0;
        opacity: 0;
        z-index: 1;
        border-radius: 20px;
        transition: all ease 300ms;
        background: rgb(9, 158, 175, 0.85);
      }

      .opacityImage {
        position: absolute;
        margin: auto;
        height: 120%;
        width: 120%;
        opacity: 0;
        z-index: 0;
        object-fit: cover;
        transition: all ease 300ms;
      }
    }
  }
}