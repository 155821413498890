.filterContainer {
  background: #FFF;
  border-radius: 20px;
  padding: 15px 10px;
  box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.20);
  position: absolute;
  top: 40px;

  &::after {
    content: '';
    width: 0;
    height: 0;
    display: inline-block;
    position: absolute;
    top: -17px;
    right: -1px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 35px solid #FFF;
  }

  .innerFilterContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px 5px;
    background: inherit;
    position: relative;
    z-index: 1;

    .customSelect {
      width: calc(20% - 5px);
      height: 35px;
      font-size: 14px;
      padding-left: 5px;
      border-radius: 20px;
      flex-shrink: 0;
      border: 1px solid #67C2CD;
    }

    .clearFilter {
      width: calc(20% - 5px);
      height: 35px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 20px;
      border: none;
      flex-shrink: 0;
      color: #FFF;
      background: #FF5E5C;
      text-align: center;
    }
  }
}