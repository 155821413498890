.invitations {
  height: 100%;
  background: #FFF;
  border-radius: 21px;
  font-family: 'Montserrat';
  padding: 10px 5px 10px 20px;
  filter: drop-shadow(-4px -6.928px 13.5px rgba(24, 93, 88, 0.13));

  .titleHeading {
    font-size: 17px;
    font-weight: 600;
    margin: 0;
  }

  .noInvitationMsg {
    text-align: center;
    margin-top: 10px;
  }

  .invitationCard {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 15px 10px 15px 0;
    border-bottom: 1px solid #d6c0c0;

    .titleAndAgo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .title {
        font-size: 13px;
        line-height: 17px;
        font-weight: 600;
        width: 50%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .ago {
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
      }
    }

    .dateAndtime {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;

      .calendarSchedule {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    .description {
      font-size: 12px;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 5px;

      .accept {
        width: 100%;
        font-size: 12px;
        border: none;
        border-radius: 10px;
        background: #099EAF;
        color: #FFF;
        padding: 2px 10px;

      }

      .decline {
        @extend .accept;
        background: #e45653;
        ;
      }
    }
  }
}