.layoutWrapper {
  display: flex;
  height: 100%;
  background-color: #099EAF;
  padding-left: 260px;

  .sidebarLayoutWrapper {
    width: 260px;
    height: 100vh;
    background-color: #099EAF;
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  .contentLayoutWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    padding: 20px 20px;
    background-color: #E5FAFF;
    border-top-left-radius: 40px;
    min-height: 100%;

    .navbarLayout {
      height: 64px;
    }

    .pageLayout {
      width: 100%;
    }
  }
}