.appNavBar {
  background-color: #fff;
  border-radius: 25px;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;

    .menuTitle {
      display: flex;
      align-items: center;
      width: 50%;
      margin-left: 10px;

      p {
        font-size: 24px;
        font-weight: 800;
        font-family: 'Montserrat';
        letter-spacing: 1px;
        color: #33AAB9;
        margin: 0;
      }
    }

    .profileMenu {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-right: 2rem;
      cursor: pointer;
      position: relative;

      .profileName {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .avatarContainer {
        cursor: pointer;
        position: relative;
        display: inline-block;

        .badgeDot {
          position: absolute;
          bottom: 0;
          right: 0;
          height: 10px;
          width: 10px;
          background-color: #56CA00;
          border-radius: 50%;
          border: 1px solid #fff;
        }
      }

      .menu {
        position: absolute;
        width: 180px;
        top: 40px;
        right: 40px;
        background-color: #fff;
        border-radius: 4px;
        overflow: hidden;
        z-index: 1000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .menuItem {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 10px 20px;
          cursor: pointer;

          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
    }
  }
}