.billing {
  padding-left: 20px;
  margin-top: 10px;
  font-family: 'Montserrat';

  .header {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  .contentArea {
    background: #FFF;
    padding: 20px 10px 50px 20px;
    margin-top: 20px;
    border-radius: 8px;
    filter: drop-shadow(-6.928px 4px 13.5px rgba(24, 93, 88, 0.13));
    border: 1px solid #cdeaed;

    .content {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .section {
        display: flex;
        flex-direction: column;
        gap: 15px;

        h5 {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
        }

        .input {
          width: 500px;
          padding: 30px 5px;
          border-radius: 20px;
          filter: drop-shadow(0 0 4.5px rgba(24, 93, 88, 0.25));
          background-color: #e5faff;
          border: 1px solid #5cb8b2;
        }

        .radioGroup {
          width: 400px;
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          gap: 10px;

          label {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            font-weight: 600;
          }
        }

        .cardInfo {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
          width: 500px;

          span {
            font-size: 14px;
            font-weight: 600;
          }

          .buttons {
            display: flex;
            gap: 5px;

            .button {
              color: #FFF;
              font-size: 14px;
              border: none;
              border-radius: 25px;
              padding: 5px 20px;

              &.save {
                background: #099eaf;
              }

              &.delete {
                background: #FF5E5C;
              }
            }
          }
        }

        .subscription {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
          width: 500px;
          padding: 5px 5px 5px 20px;
          border-radius: 31px;
          filter: drop-shadow(0 0 4.5px rgba(24, 93, 88, 0.25));
          border: 1px solid #5cb8b2;
          background-color: #e5faff;

          .currentPlan {
            font-size: 14px;
            font-weight: 600;
          }

          .planType {
            color: #FFF;
            font-size: 12px;
            padding: 11px 40px;
            border-radius: 25px;
            background: #099eaf;
          }
        }
      }
    }
  }
}