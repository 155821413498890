.profileOverview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 30px;

  .profileDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin: 0;
        margin-left: 5px;
      }

      .location {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #9A9A9A;
        gap: 2px;
        margin: 0;
        margin-top: 2px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .viewProfile {
      background-color: #099EAF;
      color: #fff;
      padding: 4px 40px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
    }

    .myProgress {
      @extend .viewProfile;
      background-color: #FCECEC;
      color: #000;
    }
  }
}