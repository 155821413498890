.caseCount {
  margin-top: 20px;
  margin-left: 10px;

  .heading {
    font-size: 17px;
    font-weight: 600;
    font-family: 'Montserrat';
  }

  .caseCards {
    width: 100%;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    margin-top: 15px;

    .card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 33%;
      padding: 10px;
      border-radius: 10px;

      .detail {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .count {
          font-weight: 650;
          margin: 0;
        }

        .caseHeading {
          font-weight: 600;
          font-size: 12px;
          margin: 0;
        }
      }
    }
  }
}