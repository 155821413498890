* {
  box-sizing: border-box;
  margin: 0;
}

body {
  background: #E5FAFF;
}

body::-webkit-scrollbar {
  width: 0px;
}

/* Full Calender Global CSS */
.fc-header-toolbar {
  margin-bottom: 1rem !important;
}

.fc .fc-timegrid-axis-frame {
  justify-content: center !important;
  font-weight: 800;
}

.fc-col-header-cell .fc-scrollgrid-sync-inner {
  background: #FAE9DD;
}

.fc-col-header-cell .fc-scrollgrid-sync-inner a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
}

.fc-prev-button {
  background: #099EAF !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.fc-next-button {
  background: #CEEBEE !important;
  color: #099EAF !important;
  font-weight: 600 !important;
}

.fc-button-group button {
  border: none !important;
}

.fc-today-button {
  background: transparent !important;
  border: none !important;
  color: #000 !important;
  font-weight: 800 !important;
}

.fc-timeGridDay-button {
  background: #CEEBEE !important;
  color: #099EAF !important;
  font-weight: 600 !important;
}

.fc-timeGridWeek-button {
  background: #099EAF !important;
  color: #fff !important;
  font-weight: 500 !important;
}

/* CSS For Comet Chat Application */
.cc-contacts-header {
  margin-bottom: 12px;
}

.cc-call-buttons {
  display: none !important;
}

/* CSS For Case Selection Dropdown In Session Form */
.case-error {
  border: 1px solid red !important;
}

.case_selection_dropdown_box {
  position: relative;
  z-index: 5;
}

.case_selection_dropdown_box .main-dropdown input[type="text"] {
  position: relative;
  width: 300px;
  font-size: 16px;
  padding: 12px;
  border-radius: 20px;
  border: 1px solid #dcdbe0;
  cursor: pointer;
}

.main_dropdown_arrow {
  position: absolute;
  right: 0;
  left: 90%;
  top: 10px;
}

.case_selection_dropdown_box .dropdown-list {
  position: absolute;
  bottom: 45px;
}

.case_selection_dropdown_box ul {
  list-style: none;
  padding: 0;
}

.case_selection_dropdown_box ul li {
  padding: 8px 8px;
  cursor: pointer;
}

.topic_inner_list {
  width: 260px;
  height: 300px;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f4f3f8;
  overflow-y: auto;
}

.topic_inner_list ul {
  padding: 0;
}

.topic_inner_list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
}

.topic_inner_list .sub-dropdown {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 100%;
  border: 1px solid #ccc;
  background-color: #f4f3f8;
  border-radius: 0 0 4px 4px;
}

.topic_inner_list .sub-dropdown ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}

.topic_inner_list .sub-dropdown ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 5px;
  cursor: pointer;
}

.topic_inner_list .sub-dropdown ul li input[type="checkbox"] {
  margin-right: 10px;
}

.topic_inner_list li:hover .sub-dropdown {
  display: block;
}

.description-box {
  display: none;
  position: absolute;
  left: 100%;
  width: 100%;
  color: #424141;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  max-height: 200px;
  overflow-y: auto;
}

.eye-icon:hover {
  color: #19a4ff;
}

.eye-icon:hover+.description-box,
.description-box:hover {
  display: block;
  font-size: 14px;
}