.notifications {
  padding-left: 20px;
  margin-top: 10px;
  font-family: 'Montserrat';

  .heading {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  .contentArea {
    background: #FFF;
    padding: 40px 20px;
    margin-top: 20px;
    border-radius: 8px;
    border: 1px solid #cdeaed;
    filter: drop-shadow(-6.928px 4px 13.5px rgba(24, 93, 88, 0.13));

    .content {
      max-width: 450px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 80px;
      padding: 10px 20px;
      border-radius: 32px;
      background-color: #099eaf;

      .emailHeading {
        color: #FFF;
      }
    }
  }
}