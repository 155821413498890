.sessionHeaders {
  margin-top: 20px;
  padding-left: 5px;
}

.title {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
}

.title .titleMark {
  display: inline-block;
  width: 15px !important;
  height: 15px;
  background: #19a4ff;
  border: 1px solid #19a4ff;
  border-radius: 3px;
}

.title .sessionTitle {
  font-size: 24px;
}

.dateAndTimeArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.dateAndTimeArea .sessionDateTime {
  margin-left: 46px;
}

.dateAndTimeArea .remainingTime {
  background: #fff;
  color: rgb(238, 101, 101);
  border: 2px solid #19a4ff;
  padding: 2px 10px;
  font-size: 16px;
  margin-top: 10px;
  margin-left: 46px;
}

.joinSessionArea {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 1.5rem;
}

.joinSessionArea .videoLogo {
  margin-top: 5px;
}

.joinSessionArea .joinButton {
  background-color: #19a4ff;
  color: #fff;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.sessionSummaryArea {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 1.5rem;
}

.sessionSummaryArea .summary {
  font-weight: 500;
  padding-left: 5px;
  text-align: left;
  border-radius: 4px;
}

.sessionTypeArea {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 1.5rem;
}

.sessionTypeArea .typeLabel {
  font-size: 18px;
  font-weight: 600;
}

.sessionTypeArea .sessionType {
  text-transform: capitalize;
  font-weight: 500;
  background: rgb(241, 197, 197);
  padding: 0px 10px;
  border-radius: 3px;
}

.notificationTimeArea {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 1.5rem;
}

.notificationTimeArea .notificationLogo {
  margin-top: 5px;
}

.notificationTimeArea .notification {
  font-size: 18px;
  font-weight: 500;
}