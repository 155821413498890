.expertiseInformation {
  padding: 20px 0 20px 30px;

  .content {
    width: 100%;
    padding: 15px;
    background: #E5FAFF;
    border-radius: 32px;
    border: 1px solid #24a5acb0;

    .formLine {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 10px;
      margin-bottom: 10px;

      .label {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin-left: 5px;
      }

      .inputText {
        width: 70%;
        height: 100px;
        border-radius: 22px;
        margin-top: 10px;
        background: #FFF;
        padding-left: 10px;
        padding-top: 10px;
        border: 1px solid #C4C4C4;
      }

      .field {
        display: flex;
        flex-direction: column;
      }
    }
  }
}