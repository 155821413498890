.friends {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  background: #FFF;
  border-radius: 8px;
  padding: 20px 30px;
  font-family: 'Montserrat';

  .header {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: 0;
  }

  .friendsCardArea {
    display: flex;
    flex-wrap: wrap;
    gap: 65px;

    .friendsCard {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-shrink: 0;

      .imageAndName {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          padding: 15px 0;
          font-size: 18px;
          font-weight: 600;
          font-family: 'Raleway';
          letter-spacing: 0.5px;
          cursor: pointer;
        }
      }

      .viewProfile {
        text-align: center;
        border: 2px solid #67C2CD;
        color: #67C2CD;
        border-radius: 50px;
        padding: 3px 20px;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .noFriendsMsg {
      margin: 0px auto;
    }
  }
}