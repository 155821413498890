.myConnection {
  height: 100%;
  margin-top: 10px;
  background: #FFF;
  border-radius: 8px;
  padding: 20px 30px;
  font-family: 'Montserrat';

  .titleHeading {
    font-size: 18px;
    font-weight: 600;
  }

  .connectionCardArea {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    width: 100%;
    gap: 65px;

    .connectionCard {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-shrink: 0;

      .imageAndName {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          padding: 15px 0;
          font-size: 18px;
          font-weight: 600;
          font-family: 'Raleway';
          letter-spacing: 0.5px;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .actions {
          width: 100%;
          text-align: center;
          border: 2px solid #67C2CD;
          color: #67C2CD;
          border-radius: 50px;
          padding: 2px 20px;
          font-weight: 600;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }

    .noConnectionMsg {
      margin: 0px auto;
    }
  }

  .paginationArea {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }
}