.personalInformation {
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 20px 0 20px 30px;

  .leftContainer {
    width: 18%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 15px;
    background: #E5FAFF;
    border-radius: 32px;
    border: 1px solid #24a5acb0;

    .profileImageArea {
      width: 170px;
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #CEEBEE;
      cursor: pointer;
    }

    .buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .button {
        border: none;
        color: #FFF;
        padding: 5px 0;
        border-radius: 30px;
      }

      .upload {
        background: #099eaf;
      }

      .remove {
        background: #FF5E5C;
      }
    }
  }

  .rightContainer {
    width: calc(60% - 10px);
    padding: 10px 15px;
    background: #E5FAFF;
    border-radius: 32px;
    border: 1px solid #24a5acb0;

    .formLine {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 10px;
      margin-bottom: 10px;

      &.inline {
        flex-direction: row;
        justify-content: space-between;

        .field {
          width: calc(50% - 20px);
        }
      }

      .label {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin-left: 5px;
      }

      .selectBox,
      .inputText {
        width: 100%;
        height: 35px;
        border-radius: 22px;
        margin-top: 10px;
        background: #FFF;
        border: 1px solid #C4C4C4;
      }

      .inputText {
        padding-left: 10px;
      }

      .field {
        display: flex;
        flex-direction: column;
      }
    }
  }
}