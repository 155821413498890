.dashboard {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 20px;

  .leftContainer {
    width: calc(75% - 10px);
  }

  .rightContainer {
    width: calc(25% - 10px);
  }
}