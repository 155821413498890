.clinicalSystem {
  font-family: 'Montserrat';

  .header {
    display: flex;
    gap: 20px;
    padding: 20px 0 20px 40px;
    border-radius: 20px;
    background: #56BBC6;

    .categoryImage {
      width: 200px;
      height: 200px;
      background: #FFF;
      align-content: center;
      text-align: center;
      border-radius: 100%;
      box-shadow: -8px 5px 6px 0px rgba(0, 0, 0, 0.2);

      img {
        object-fit: contain;
      }
    }

    .categoryTitle {
      display: flex;
      flex-direction: column;
      margin-top: 25px;

      .clinicalTitle {
        color: #E5FAFF;
        font-size: 21px;
        font-weight: 500;
        margin-left: 8px;
      }

      .title {
        font-size: 45px;
        color: #E5FAFF;
        font-weight: 700;
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 50px 0px;
    margin-top: 5px;
    padding: 0px 40px;
    width: 100%;

    .clinicalBox {
      display: flex;
      align-items: center;
      justify-content: start;
      width: calc(50% - 20px);
      gap: 25px;

      .clinicalImage {
        height: 160px;
        width: 160px;
        border-radius: 100%;
        box-shadow: -8px 5px 6px 0px rgba(0, 0, 0, 0.2);
      }

      .clinicalTitle {
        display: flex;
        flex-direction: column;
        gap: 15px;

        span {
          font-size: 21px;
          color: #000;
          font-weight: 600;
        }

        button {
          background: #099eaf;
          border: none;
          color: #FFF;
          font-size: 14px;
          font-weight: 400;
          border-radius: 18px;
          padding-bottom: 5px;
          width: 150px;
        }

        .disabledButton {
          opacity: 0.5;
          background-color: grey;
          cursor: no-drop;
        }
      }
    }
  }
}