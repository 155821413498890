.sessions {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 10px;
  padding-left: 20px;
  font-family: 'Montserrat';

  .leftContainer {
    width: 75%;
    display: inline-flex;
    flex-direction: column;

    .headingContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .heading {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }

      .addSession {
        display: inline-block;
        background: #FF5E5C;
        color: #fff;
        border: none;
        text-align: center;
        border-radius: 20px;
        padding: 3px 30px;
      }
    }

    .sessionTabs {
      margin-top: 20px;
    }
  }

  .rightContainer {
    width: calc(25% - 20px);
  }
}