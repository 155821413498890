.findConnection {
  height: 100%;
  margin-top: 10px;
  background: #FFF;
  border-radius: 8px;
  padding: 20px 30px;
  font-family: 'Montserrat';

  .headingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .heading {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }

    .searchContainer {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;

      .searchIcon {
        position: absolute;
        color: #776e6e;
        top: 5px;
        left: 8px;
        font-size: 20px;
      }

      input[type=text] {
        width: 350px;
        height: 30px;
        border-radius: 20px;
        border: 2px solid #56d1e0;
        padding-left: 30px;

        &:focus-visible {
          border: 3px solid #56d1e0;
          outline: none;
        }
      }

      .filterIcon {
        color: #8b8f8f;
        font-size: 28px;
        cursor: pointer;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.2);
        }
      }

      .filterIconActive {
        @extend .filterIcon;
        color: #56d1e0;
      }
    }
  }

  .connectionCardArea {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    width: 100%;
    gap: 65px;

    .connectionCard {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-shrink: 0;

      .imageAndName {
        display: flex;
        flex-direction: column;
        align-items: center;

        .name {
          display: flex;
          flex-direction: column;
          padding: 15px 0;
          font-size: 18px;
          font-weight: 600;
          font-family: 'Raleway';
          text-align: center;
          letter-spacing: 0.5px;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          .title {
            font-size: 14px;
            color: #56d1e0;
            text-decoration: none !important;

            &:hover {
              text-decoration: none !important;
            }
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .actions {
          width: 100%;
          text-align: center;
          border: 2px solid #67C2CD;
          color: #67C2CD;
          border-radius: 50px;
          padding: 2px 20px;
          font-weight: 600;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }

    .noConnectionMsg {
      margin: 0px auto;
    }
  }
}