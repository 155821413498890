.myProfile {
  margin-top: 10px;
  font-family: 'Montserrat';

  .header {
    padding: 0 20px 0 20px;

    h5 {
      font-weight: 600;
      letter-spacing: 0.5px;
    }
  }

  .myProfileTabs {
    margin-top: 20px;
  }

  .content {
    background: #FFF;
  }

  .submitButton {
    border-radius: 30px;
    color: #FFF;
    border: none;
    margin-top: 20px;
    padding: 8px 30px;
    background: #099eaf;
    float: right;
  }
}