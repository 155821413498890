.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .innerContainer {
    background: #fff;
    padding: 50px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 448px;
    text-align: center;

    .logo {
      img {
        margin-bottom: 10px;
      }
    }

    .title {
      margin-bottom: 10px;
      font-size: 1.5rem;
      color: rgba(58, 53, 65, 0.87);
    }

    .inputContainer {
      margin-bottom: 10px;
      text-align: left;
      color: rgba(58, 53, 65, 0.68);

      input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        height: 45px;
      }

      .passwordInput {
        display: flex;
        align-items: center;
        position: relative;

        .eyeIcon {
          position: absolute;
          right: 10px;
          cursor: pointer;
        }
      }
    }

    .options {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .privacyPolicy {
        display: flex;
        align-items: center;
        gap: 5px;
        color: rgba(58, 53, 65, 0.68);

        input {
          transform: scale(1.3);
          margin-right: 5px;
        }

        a {
          text-decoration: none;
          color: #16b1ff;
        }
      }

      a {
        text-decoration: none;
        color: #16b1ff;
      }
    }

    .signupButton {
      width: 100%;
      padding: 10px;
      font-weight: 600;
      color: #fff;
      border: none;
      border-radius: 5px;
      background-color: #16b1ff;
      cursor: pointer;
    }

    .footer {
      margin-top: 20px;
      color: rgba(58, 53, 65, 0.68);

      span {
        a {
          margin-left: 5px;
          text-decoration: none;
          color: #16b1ff;
        }
      }
    }
  }
}