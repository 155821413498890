.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.cardContent {
  background: #fff;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 448px;
  text-align: center;

  .logo img {
    margin-bottom: 10px;
  }

  .title {
    font-size: 1.4993rem;
    margin-bottom: 1rem;
    line-height: 1.334;
    letter-spacing: 0;
    font-weight: 700;
    color: rgba(58, 53, 65, 0.87);
  }

  .button {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1.75rem;
    background-color: #16b1ff;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
  }

  .linkContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(58, 53, 65, 0.68);

    .link {
      margin-left: 0.5rem;
      color: #16b1ff;
      text-decoration: none;
    }
  }

  .inputContainer {
    margin-bottom: 10px;
    text-align: left;
    color: rgba(58, 53, 65, 0.68);

    label {
      display: block;
    }
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 45px;
    margin-top: 10px;
  }

  .passwordContainer {
    display: flex;
    align-items: center;
    position: relative;

    .eyeIcon {
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }
}