.upcomingSession {
  background: #fff;
  padding: 12px 15px 0px 15px;
  border-radius: 15px;
  font-family: 'Montserrat';

  .heading {
    font-size: 16px;
    font-weight: 700;
  }

  .sessionCard {
    min-height: 80px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
    cursor: pointer;

    .innerSessionCard {
      padding: 5px 15px;
      background: #FAE9DD;
      border-radius: 20px;

      .firstRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .titleAndRole {
          display: flex;
          flex-direction: column;

          .sessionTitle {
            font-size: 14px;
            font-weight: 600;
          }

          .role {
            font-size: 10px;
            font-weight: 600;
            color: #E45653;
          }
        }

        .playButton {
          font-size: 20px;
          color: #E45653;
          cursor: pointer;
        }
      }

      .secondRow {
        display: flex;
        margin-top: 5px;

        .sessionTime {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    .allSessionButton {
      margin: 40px 0 15px 0;
      padding: 2px 10px;
      color: #fff;
      border-radius: 20px;
      text-align: center;
      background: #E45653;
    }

    .noSessionMessage {
      display: flex;
      font-size: 14px;
      align-items: center;
      justify-content: center;
    }
  }
}