.dailoagBoxLayer {
  background: rgba(0, 0, 0, 0.60);
  position: fixed;
  overflow-y: scroll;
  padding: 50px 0;
  z-index: 5;
  inset: 0;

  .dialogBox {
    width: 100%;
    max-width: 700px;
    min-height: 621px;
    border-radius: 20px;
    background: #099eaf;
    font-family: 'Montserrat';
    margin: auto;

    .dialogTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;

      span {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: #FFF;
      }

      .closeButton {
        cursor: pointer;
      }
    }

    .dialogContent {
      width: 100%;
      padding: 20px 15px;
      background: #FFF;
      border-radius: 20px;
    }
  }
}