.candidateFeedback {
  margin-top: 20px;
  margin-left: 10px;

  .heading {
    font-size: 17px;
    font-weight: 600;
    font-family: 'Montserrat';
  }

  .feedbackTable {
    margin-top: 15px;
  }
}