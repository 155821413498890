.sessionContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 0 20px 20px;
  background: #FFF;
  border-radius: 8px;
  border: 1px solid #cdeaed;
  filter: drop-shadow(-4px -6.928px 13.5px rgba(24, 93, 88, 0.13));

  .sessionCard {
    width: calc(33% - 20px);
    background: #099EAF;
    border-radius: 18px;
    min-height: 215px;
    cursor: pointer;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #FFF;
      font-size: 14px;
      padding: 10px 15px 0 15px;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .content {
      background: #CEEBEE;
      border-radius: 18px;
      height: 171px;
      margin-top: 5px;

      .line {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 12px;
        font-weight: 600;
        padding: 10px 0 5px 10px;
      }

      .userSessionLabel {
        display: inline-flex;
        color: #FFF;
        font-size: 12px;
        border-radius: 5px;
        padding: 3px 10px;
        background: #099EAF;
        margin: 15px 0 0 15px;
      }
    }
  }

  .noSessionMsg {
    margin: 0px auto;
  }
}